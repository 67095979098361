<template>
    <div
        class="real-friend-meeting-post-detail"
        :class="{ considerInterestedButton: from === 'all' }"
        @scroll="onScroll"
    >
        <StackRouterHeaderBar
            :class="{ 'show-title': showTitle }"
            :title="headerTitle"
            :showTitle="showTitle"
            :from="`RealFriendMeetingPostDetailPage`"
        />
        <div class="post-detail-base">
            <div class="post-detail-content" :class="{ 'more-padding': showBottomButton }">
                <div class="due-created f-13 m-b-4">
                    <p class="title f-bold">{{ post.title }}</p>
                    <div class="info">
                        <p class="created c-text-lighter f-regular flex-wrap m-l-8">{{ postedAt }}</p>
                        <div class="view-count">
                            <img class="eye" src="@/assets/images/eye-image.png" />
                            <span class="count">{{ post.view_count }}</span>
                        </div>
                    </div>
                </div>
                <div @click="clickBlindProfile" class="host-info">
                    <div class="default-photo" :class="{ logo: isAdminPost }">
                        <img class="logo-symbol" v-if="isAdminPost" :src="require('@/assets/images/logo_symbol.svg')" />
                        <img v-else src="@/assets/images/default_profile.svg" />
                    </div>
                    <div class="host-nickname-verify">
                        <div class="nickname">{{ nickname }}</div>
                        <div v-if="!isAdminPost && badgeTitle" class="verify">
                            <span class="verifiy-info">{{ badgeTitle }}</span>
                            <span> 인증 완료</span>
                            <i class="material-icons">admin_panel_settings</i>
                        </div>
                    </div>
                </div>
                <div class="line" />
                <!-- <div v-if="isAdminPost" class="event-comment" v-html="$translate('REALFRIEND_EVENT_COMMENT')" /> -->

                <div class="age-region" :class="{ male: post.gender === 0, female: post.gender === 1 }">
                    <span :class="{ male: post.gender === 0, female: post.gender === 1 }">
                        {{ `${post.gender === 0 ? '남' : '여'}(${post.age + '세'})` }}
                        <span style="color: #c8c8de">ㅣ</span> {{ `${regionName}` }}
                    </span>
                </div>

                <p class="desc" v-html="content" />
            </div>
            <div v-if="photoURL" class="post-detail-photo">
                <img class="background" :src="photoURL" />
            </div>

            <!--  카드 & 버튼 -->
            <hr class="photo-divider" v-if="!photoURL" />
            <div v-if="showParticipantCard" class="post-detail-participants">
                <div class="title-wrapper m-b-24">
                    <p class="requests-title" v-html="$translate('INTERESTED_CARDS')" />
                    <i class="material-icons m-l-5 f-18" @click="showToast">info</i>
                </div>
                <div class="cards">
                    <RealFriendMeetingCard v-for="card in interestedCards" :card="card" :key="card.id" />
                </div>
                <span class="no-cards" v-if="interestedCards.length === 0">아직 관심을 보인 분이 없어요</span>
            </div>
            <img
                v-if="isAdminPost"
                class="thumb-nail"
                @click="onClickThumbnail()"
                src="@/assets/images/real-friend/thumbnail_9.png"
            />
            <div class="bottom-info" :class="{ 'm-b-40': showParticipantCard }">
                <span class="bottom-info-title">이용방법</span>
                <div class="bottom-info-content" v-for="info in infos" :key="info">
                    <div class="bottom-info-row-left"></div>
                    <div class="bottom-info-row-right" v-html="info" />
                </div>
            </div>
        </div>
        <BottomButton
            v-if="showBottomButton"
            :disabled="disabled"
            :label="bottomButtonLabel"
            @click="onClickInterestButton"
        />
    </div>
</template>
<script>
import RealFriendMeetingCard from '@/routes/realfriend-meeting/RealFriendMeetingCard'
import realfriendMeetingService from '@/services/realfriend-meeting'
import verificationBadgeService from '@/services/verification-badge'

export default {
    name: 'RealFriendMeetingPostDetailPage',
    components: { RealFriendMeetingCard },
    props: {
        post: {
            type: Object,
            required: true,
        },
        from: {
            type: String,
        },
    },
    data: () => ({
        showTitle: false,
        badges: [],
        badgeTitle: '',
    }),
    async created() {
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'IntroduceMyFriend',
                option: {
                    funnel: 'click_post',
                },
            },
        })

        await this.init()
    },
    computed: {
        headerTitle() {
            return this.post.title
        },
        disabled() {
            // 버튼 활성화여부 체크
            if (!this.post.host_nickname) return true

            return this.post.my_request !== null
        },
        content() {
            return this.post.content.split(/\n/).join('<br>')
        },
        showBottomButton() {
            // 내가 작성한 글의 경우 관심있어요 버튼이 보이면 안됨
            return this.post.user_id !== this.$store.getters.me.id
        },
        showParticipantCard() {
            if (!this.showBottomButton) {
                if (this.post.requests !== null) {
                    // 주선자 본인이고 소개팅을 요청한 사람이 있는 경우에
                    return true
                }
            }
            return false
        },
        ageGender() {
            return `${this.post.age}세 (${this.post.gender ? '여' : '남'})`
        },
        regionName() {
            const states = this.$store.getters.states
            const state = states.find(s => s.id === this.post.state_id)
            return state.name
        },
        postedAt() {
            const days = this.$moment().diff(this.post.created_at, 'days')
            const hours = this.$moment().diff(this.post.created_at, 'hours')
            const mins = this.$moment().diff(this.post.created_at, 'minutes')

            if (days) {
                return `${days}일 전`
            } else if (hours) {
                return `${hours}시간 전`
            } else {
                return `${mins}분 전`
            }
        },
        nickname() {
            if (!this.post.host_nickname) return `---`

            if (this.post.user_id === this.$store.getters.me.id) {
                return `${this.post.host_nickname} (주선자)`
            } else {
                return this.post.host_nickname
            }
        },

        interestedCards() {
            if (!this.showBottomButton) {
                return this.post.requests || []
            } else {
                return []
            }
        },
        bottomButtonLabel() {
            if (!this.post.host_nickname) return '탈퇴한 유저입니다.'
            return '관심있어요'
        },
        photoURL() {
            if (!this.post.photo_url) return false
            if (this.post.photo_url.includes('medium')) {
                return this.post.photo_url.replace(/medium/, 'original')
            }

            return this.post.photo_url
        },
        infos() {
            if (this.showBottomButton) {
                return [
                    '주선자가 관심을 수락하면 채팅방이 열리고, 주선자의 친구에 대한 자세한 정보를 받을 수 있어요.',
                    '친구가 아니라 주선자에게 호감을 보이는 언행을 할 경우, 이용정지 처리될 수 있어요.',
                    '주선자가 아끼는 실제 친구를 소개해 주는 만큼 <span class="c-primary">매너와 예의를 지켜주세요!</span>',
                ]
            } else {
                return [
                    '관심회원의 관심을 수락하면 채팅방이 열립니다. 관심회원에게 친구의 정보를 추가적으로 제공해주세요!',
                    '친구에게 카카오톡 등을 통해 소개팅 의사를 물어보세요.<br><span class="c-primary">(친구는 바닐라브릿지에 가입할 필요가 없어요!)</span>',
                ]
            }
        },
        isAdminPost() {
            //  이벤트용 어드민 계정 예외처리 다시적용
            // if (this.post.user_id === 188399 || this.post.user_id === 188091) {
            //     return true
            // }
            // if (this.post.id === 1322) return true

            return false
        },
        // isEventUserFirst() {
        //     if (this.post.id === 68) {
        //         return true
        //     }

        //     return false
        //     // return true
        // },
        // isEventUserSecond() {
        //     if (this.post.id === 69) {
        //         return true
        //     }

        //     return false
        //     // return true
        // },
    },
    methods: {
        async init() {
            // 게시자 뱃지정보 확인을 위해 서버에 해당 유저 뱃지정보 요청
            try {
                const res = await verificationBadgeService.get(this.post.user_id)
                // 해당 유저의 전체 뱃지 중 summary badge만 추출
                this.badges = res.filter(r => {
                    return (r.display_method || '').includes('summary')
                })
                this.badges.forEach((badge, idx) => {
                    if (idx === this.badges.length - 1) this.badgeTitle += this.summaryBadgeTitle(badge.name)
                    else this.badgeTitle += `${this.summaryBadgeTitle(badge.name)}, `
                })
            } catch (e) {
                this.$toast.error(e.data)
            }

            // 관심있어요 탭에서 게시글 조회 시 읽었다는 체크 서버로 전송
            if (this.from === 'interested') {
                const {
                    host_checked: hostChecked,
                    participant_checked: participantChecked,
                    id,
                    introduce_id,
                } = this.post.my_request
                if (hostChecked === true && participantChecked === false) {
                    try {
                        await realfriendMeetingService.participantCheck(introduce_id, id)
                        this.$store.commit('updateReadInterest', introduce_id)
                    } catch (e) {
                        this.$toast.error(e.data)
                    }
                }
            }
        },
        onScroll(event) {
            this.showTitle = event.target.scrollTop > 200
        },
        async onClickInterestButton() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'IntroduceMyFriend',
                    option: {
                        funnel: 'click_interest_cta',
                    },
                },
            })

            // 성별체크 우선
            if (this.$store.getters.me.gender !== this.post.gender) {
                // 바닐라 결제
                const { vanilla: currentVanilla } = this.$store.getters.badges
                const { vanilla } = this.$store.getters.settings
                if (currentVanilla < vanilla.participate_meeting) {
                    // 바닐라가 부족한 경우
                    const idx = await this.$modal.basic({
                        body: `바닐라 ${vanilla.participate_meeting - currentVanilla}개가 부족합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) this.$stackRouter.push({ name: 'InAppStorePage' })
                } else {
                    // 바닐라가 충분한 경우 바닐라 과금 후 관심 전송
                    const idx = await this.$modal.basic({
                        body: `바닐라 ${vanilla.participate_meeting}개를 사용합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        await this.requestInteresting()

                        this.$nativeBridge.postMessage({
                            action: 'sendFirebaseEvent',
                            value: {
                                category: 'IntroduceMyFriend',
                                option: {
                                    funnel: 'complete_interest_cta',
                                },
                            },
                        })
                    }
                }
            } else {
                this.$toast.error('이성에게만 관심을 표현할 수 있어요')
            }
        },
        async requestInteresting() {
            try {
                this.$loading(true)
                const { introduce_request: request, msg } = await realfriendMeetingService.request(this.post.id)
                this.$store.dispatch('loadBadges')

                this.$store.commit('updateRequestPost', { id: this.post.id, request: request })
                this.$toast.success(msg)
            } catch (e) {
                this.$hooks.handlePossibleVanillaShortageError(e.data)
            } finally {
                this.$loading(false)
            }
        },
        showToast() {
            const msg = '신청 후 7일간만 확인할 수 있어요!\n' + '휴면/탈퇴한 회원의 프로필은 사라져요.'
            this.$toast.success(msg)
        },
        clickBlindProfile() {
            this.$toast.success('사진이 블라인드된 프로필만 제공됩니다.')
            this.$stackRouter.push({
                name: 'BlindUserDetailPage',
                props: {
                    nickname: this.post.host_nickname,
                    userId: this.post.user_id,
                    from: 'realfriendmeet',
                },
            })
        },
        summaryBadgeTitle(name) {
            return name.replace('인증', '')
        },

        onClickThumbnail() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://www.youtube.com/watch?v=yxAMhnfCegs',
            })
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .stack-router-header-bar {
    z-index: 3;
    position: fixed;
    top: 0;
    background-color: transparent;

    .left-btn > .material-icons {
        color: $grey-08;
    }

    &.show-title {
        background-color: white;
        border-bottom: solid 1px $grey-03;
        margin-bottom: 16px;

        .material-icons {
            color: $grey-08 !important;
        }
    }
}

.real-friend-meeting-post-detail {
    padding: 28px 0;
    line-height: normal;
    letter-spacing: -0.2px;
    overflow-y: auto;

    &.considerInterestedButton {
        $bottom-button-height: 81px;
    }

    .post-detail-base {
        margin-top: 55px;
    }

    &.more-padding {
        $bottom-buttom-height: 80px;
        padding-bottom: calc(#{$bottom-buttom-height} + 52px);
    }

    .created {
        color: $grey-06;
        font-size: 13px;
    }

    .due-created {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            color: black;
            font-size: 20px;
            margin-left: 16px;
            flex: auto;
            @include f-medium;
        }

        .info {
            margin-right: 16px;
            text-align: right;
            flex: none;
            .view-count {
                margin-top: 8px;
                .eye {
                    width: 15px;
                    height: 10px;
                    margin-right: 4px;
                }
                .count {
                    font-size: 12px;
                    color: $grey-05;
                    @include f-regular;
                }
            }
        }
    }

    .host-info {
        @include flex-row;
        @include f-medium;
        margin-left: 16px;
        margin-top: 14px;

        .default-photo {
            height: 24px;
            width: 24px;
            padding: 2px;
            border-radius: 40%;
            border: solid 1px $grey-03;
            background-color: $grey-01;
            display: flex;
            justify-content: center;

            &.logo {
                height: 28px;
                width: 28px;
                padding: 1px;
            }

            .logo-symbol {
                width: 24px;
                height: 24px;
            }
        }

        .host-nickname-verify {
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            .nickname {
                color: $grey-08;
                font-size: 14px;
                margin-bottom: 4px;
            }

            .verify {
                i {
                    color: $purple-primary;
                    font-size: 18px;
                    margin-left: 3px;
                }

                color: $purple-primary;
                font-size: 12px;
                display: flex;
                align-items: center;

                .verifiy-info {
                    @include f-bold;
                    text-decoration: underline;
                    margin-right: 3px;
                }
            }
        }
    }

    .line {
        height: 1px;
        width: 100%;
        background-color: $grey-01;
        margin-top: 28px;
        margin-bottom: 34px;
    }

    .event-comment {
        margin: -18px 16px 26px 16px;
        background-color: $grey-01;
        border-radius: 12px;
        padding: 16px;
        color: black;
    }

    .thumb-nail {
        margin: 16px;
        width: calc(100vw - 32px);
    }

    .age-region {
        @include items-center;
        @include f-bold;
        display: inline-block;
        border-radius: 6px;
        padding: 3px 0px;
        margin: 0 16px;
        // color: white;
        font-size: 13px;

        // margin-left: 0px !important;
        background-color: white;
        // font-size: 13px;

        // @include items-center;
        // @include f-bold;

        &.male {
            color: #93b3ec;
        }

        &.female {
            color: #f2adac;
        }
    }

    .post-detail-photo {
        margin-top: 40px;
        width: 100vw;
        height: 100vw;

        .background {
            width: 100%;
            height: 100%;
        }
    }

    .desc {
        margin: 27px 16px 0 16px;
        line-height: 1.6;
        font-size: 15px;
        color: black;

        @include f-regular;
    }

    .bottom-info {
        margin: 40px 16px 120px 16px;
        border-radius: 10px;
        background-color: $grey-01;
        padding: 16px 14px;

        .bottom-info-title {
            font-size: 16px;
            @include f-medium();
            color: black;
        }
        .bottom-info-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 12px;

            .bottom-info-row-left {
                width: 4px;
                height: 4px;
                border-radius: 2px;
                margin-right: 8px;
                background-color: $grey-07;
            }

            .bottom-info-row-right {
                font-size: 12px;
                color: black;

                ::v-deep span {
                    @include f-bold();
                    color: $purple-primary;
                }
            }
        }
    }
}

.photo-divider {
    margin-top: 48px;
    margin-bottom: 48px;
    border-color: $grey-02;
    height: 1px;
}

.post-detail-host,
.post-detail-participants {
    margin-top: 48px;
    padding: 0 16px 32px 16px;
    line-height: normal;
    letter-spacing: -0.2px;

    .title-wrapper {
        @include flex-row;
        @include items-center;
    }

    .requests-title {
        color: black;
        font-weight: 500;
        font-size: 18px;

        @include f-medium;
    }

    .cards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 12px;
        column-gap: 12px;

        .no-cards {
            color: $grey-08;
            font-size: 14px;
        }
    }
}

.bottom-button {
    position: fixed;
}
</style>

<template>
    <div class="card" :class="{ 'card-flipped': onFlip }">
        <div class="card-inner">
            <div
                v-if="showCard"
                class="realfriend-meeting-profile"
                :class="{ 'non-active': !isActiveUser }"
                @click="goToUserDetail"
            >
                <div class="img-wrapper">
                    <img v-if="isActiveUser" :src="photoUrls[0]" />
                    <img v-else style="width: 52px; height: 52px" :src="require(`@/assets/images/person.svg`)" alt="" />
                    <div class="nickname-status c-white" :class="{ 'non-active': !isActiveUser }">
                        <p class="p-l-8 nickname f-medium f-15 m-b-8">{{ card.user_nickname }}</p>
                    </div>
                </div>
                <div class="realfriend-meeting-profile-button-wrapper" :class="{ 'non-active': !isActiveUser }">
                    <button
                        class="btn btn-facebook"
                        :class="{ accept: status !== 'checked' }"
                        @click.stop="onClickButton"
                    >
                        {{ buttonLabel }}
                    </button>
                </div>
            </div>
            <div v-if="!showCard" class="realfriend-meeting-card" @click.stop="flipCard">
                <div class="realfriend-meeting-card-inside center" :class="[gender === 0 ? 'male' : 'female']">
                    <img :src="logo" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import realfriendService from '@/services/realfriend-meeting'

export default {
    name: 'RealFriendMeetingCard',
    data: () => ({
        onFlip: false,
        status: null,
        forceShowContactLabel: false,
        chatId: null,
    }),
    props: {
        card: {
            type: Object,
            required: false,
        },
    },
    computed: {
        showCard() {
            return this.status !== 'request'
        },
        gender() {
            return this.card.gender
        },
        logo() {
            return this.gender === 0
                ? require('@/assets/images/logo_male.svg')
                : require('@/assets/images/logo_female.svg')
        },
        photoUrls() {
            return this.card.photos || []
        },
        statusMessage() {
            // 채팅방 연결 이후 상태메시지 노출 예정
            return ''
        },
        buttonLabel() {
            if (this.status === 'checked') {
                return '관심 수락하기'
            } else {
                return '채팅방 이동'
            }
        },
        isActiveUser() {
            const { enabled, hidden, is_dormant: isDormant } = this.card
            return enabled && !hidden && !isDormant
        },
    },
    methods: {
        async flipCard() {
            try {
                const agreed = localStorage.getItem('agree_privacy_policy')
                if (!agreed) {
                    const idx = await this.$modal.basic({
                        title: '개인정보 보호 안내',
                        body: this.$translate('PRIVACY_WARNING'),
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        await this.sendFlipRequest()
                        localStorage.setItem('agree_privacy_policy', true)
                    }
                } else {
                    await this.sendFlipRequest()
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        async sendFlipRequest() {
            await realfriendService.check(this.card.introduce_id, this.card.id)
            this.$store.commit('updateCardStatus', this.card)

            setTimeout(() => {
                this.status = 'checked'
                this.onFlip = false
            }, 100)
        },
        initStatus() {
            this.status = this.card.accept_status
            this.chatId = this.card.chat_id || null
        },
        async onClickButton() {
            try {
                if (!this.isActiveUser) {
                    this.$toast.error('휴면 혹은 탈퇴한 회원입니다')
                    return
                }
                if (this.status === 'checked') {
                    const idx = await this.$modal.basic({
                        body: '관심을 수락하고 채팅방을 오픈합니다. 채팅방에서 연락처를 보낼 수 있습니다.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        try {
                            const { msg, chat_id: chatId } = await realfriendService.accept(
                                this.card.introduce_id,
                                this.card.id,
                            )
                            this.card.chat_id = chatId
                            this.$store.commit('updateCardStatus', this.card)

                            this.$toast.success(msg)
                            this.status = 'accepted'
                            this.chatId = chatId
                        } catch (e) {
                            this.$toast.error(e.data)
                        }
                    } else {
                        return
                    }
                } else if (this.status !== 'request' && this.status !== 'checked') {
                    this.$stackRouter.pop()
                    this.onClickChatItem(this.card.chat_id || this.chatId)
                }
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
        async onClickChatItem(chatId) {
            try {
                this.$loading(true)

                const chats = this.$store.getters.chats
                const unreadCount = chats.unread

                await this.$store.dispatch('loadChat', {
                    chatId: chatId,
                    force: true,
                })

                this.$router.push({
                    name: 'ChatroomPage',
                    params: { unreadCount },
                })
            } catch (e) {
                // 챗아이디 없는 경우
                this.$toast.error(e)
            } finally {
                this.$loading(false)
            }
        },
        goToUserDetail() {
            if (!this.isActiveUser) {
                this.$toast.error('휴면 혹은 탈퇴한 회원입니다')
                return
            }
            const photos = this.photoUrls.map(p => ({ url: p }))
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.card.user_id,
                    photos: photos,
                    from: 'TodayMeetingCard',
                },
            })
        },
        checkVanilla(status) {
            const { vanilla: currentVanilla } = this.$store.getters.badges
            const { vanilla } = this.$store.getters.settings

            let lackOfVanilla = false
            let insufficientVanilla = 0

            if (this.me === 'host') {
                if (status === 'openProfile') {
                    if (currentVanilla < vanilla.open_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.open_meeting - currentVanilla
                    } else {
                        this.$toast.success(`바닐라 ${vanilla.open_meeting}개를 사용하여 프로필을 오픈했어요`)

                        return true
                    }
                } else if (status === 'accepted') {
                    if (currentVanilla < vanilla.accept_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.accept_meeting - currentVanilla
                    } else {
                        return true
                    }
                }
            } else if (this.me === 'participant') {
                if (status === 'openProfile') return true
                if (status === 'accepted') {
                    if (currentVanilla < vanilla.accept_meeting) {
                        lackOfVanilla = true
                        insufficientVanilla = vanilla.accept_meeting - currentVanilla
                    } else {
                        return true
                    }
                }
            }

            if (lackOfVanilla) {
                this.$modal
                    .basic({
                        body: `바닐라 ${insufficientVanilla}개가 부족합니다.`,
                        buttons: [
                            {
                                label: 'CLOSE',
                                class: 'btn-default',
                            },
                            {
                                label: 'GO_TO_STORE',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            this.$stackRouter.push({ name: 'InAppStorePage' })
                        }
                    })
            }
        },
    },
    mounted() {
        this.initStatus()
    },
}
</script>
<style lang="scss" scoped>
$column-gap: 12px;
$side-padding: 16px;
$card-width: calc((100vw - #{$side-padding} * 2 - #{$column-gap}) / 2);
$card-height: calc(#{$card-width} * 1.27);

.card,
.card-inner {
    width: $card-width;
    height: $card-height;
    background-color: transparent;
    border: none;
}

.card {
    perspective: 2000px;
    transition: transform 1s;
    box-shadow: none;

    &.card-flipped {
        .card-inner {
            transform: rotateY(180deg);
        }
    }
}

.realfriend-meeting-profile,
.realfriend-meeting-card {
    width: 100%;
    height: 100%;
    background-color: $grey-09;

    &.non-active {
        background-color: $grey-03;
    }
}

.realfriend-meeting-card {
    transform: rotateY(180deg);
    padding: 4px;
    border-radius: 10px;

    &-inside {
        height: 100%;
        width: 100%;
        border-radius: 6px;

        &.male {
            border: 1px solid $blue-man;
        }

        &.female {
            border: 1px solid $pink-woman;
        }
    }
}

.realfriend-meeting-profile {
    @include flex;

    .img-wrapper {
        width: $card-width;
        height: $card-width;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%;
        }

        .nickname-status {
            width: 100%;
            height: 48px;
            position: absolute;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(67, 66, 69, 0), $grey-09 96%);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            @include flex;

            &.non-active {
                //background-image: none;
            }
        }

        .nickname {
            font-weight: 500;
        }
    }

    &-button-wrapper {
        padding: 8px;
        padding-top: 0;
        height: calc(#{$card-height} - #{$card-width});

        @include center;

        &.non-active {
            background-color: #423e3e;
        }

        button {
            width: 100%;
            height: 36px;
            font-size: 14px;
            font-weight: 500;

            &.accept {
                background-color: white;
                color: $blue-facebook;
            }

            &.contact {
                background-color: white;
                color: $pink-deep;
            }
        }
    }
}
</style>
